<template>
  <div v-if="isReady" class="Reports__View">
    <EntityListLayout v-if="tabs.length" :tabs="tabs" ref="entity" @onNewEntitySave="onNewEntitySave">
      <template #title>ניהול הגדרות ומשובים בקורס {{ course.name }}</template>
      <template #button>
        <!-- <b-button v-if="isAdmin"  @click="showNewEntity">הוספת דוח חדש</b-button> -->
        <a @click="addLecturesFeedbackStatement" class="link-button">
          הוספת היגד
        </a>
      </template>
      <template v-slot:counter="slotProps">
        סה"כ <b>{{ getTotalRecords(slotProps) }} </b>
      </template>
    </EntityListLayout>
  </div>
</template>
<script>
import CoursesService from "@/services/CoursesService";
import EntityListLayout from "@/components/EntityListLayout.vue";
import LecturesFeedbackStatementEntity from "@/views/LecturesFeedbackStatementEntity.vue";
import DialogAddLecturesFeedbackStatement from '@/components/DialogAddLecturesFeedbackStatement.vue';
import {
  ModalProgrammatic as Modal,
} from 'buefy';
import VehicleContractEntity from "@/views/VehicleContractEntity.vue";
// import TableActions from "@/services/TableActionsService";
import FieldService from "@/services/FieldService";
// import FilterService from "@/services/FilterService";
import NewReportEntity from "@/components/Entities/NewReportEntity.vue";
import { saveAs } from "file-saver";

export default {
  name: "Reports",
  data() {
    return {
      tabs: [],
    };
  },
  components: {
    EntityListLayout,
  },
  computed: {
    isUserProfile() {
      return this.$store.state.auth.profileId;
    },
    isAdmin() {
      return this.$store.state.auth?.user?.claims?.isAdmin === "True";
    },
    tabName() {
      return this.selectedTab && this.selectedTab.title;
    },
    course() {
      return this.$store.state.auth.course;
    },
    excelMimeType() {
      return "application/vnd.ms-excel";
    },
    isLeasingCompany() {
      return this.$store.state.profile?.IsLeasingCompany;
    },
    isEndUser() {
      return !this.isLeasingCompany;
    },
    isReady() {
      return this.isLeasingCompany !== null;
    },
  },
  created() {
    if (this.isReady) {
      this.create();
    }
  },
  methods: {
    create() {
      const lessonsFields = FieldService.list("lecturesFeedbackStatements");
      // const reportsfilters = FilterService.list();

      const sideViewEntity = {
        component: LecturesFeedbackStatementEntity,
        preview: true,
        readOnly: !this.isAdmin,
        title: "פרטי היגד",
        recordUrl: "books",
        recordTitle: "הצג ספר",
        hideRecord: true
      };

      // const excelExport = [
      //   {
      //     title: "יצוא טבלה",
      //     action: this.export,
      //   },
      //   {
      //     title: "יצוא מקובץ לפי עיריות",
      //     action: this.exportByMunic,
      //   },
      //   {
      //     title: "יצוא מקובץ לפי לקוח",
      //     action: this.exportByClient,
      //   },
      // ];
      const fields = [
        // { ...lessonsFields.ImageUrl, checked: true },
        { ...lessonsFields.Id, checked: true },
        { ...lessonsFields.Name, checked: true },
        { ...lessonsFields.Description, checked: true },
        { ...lessonsFields.TypeName, checked: true },
        { ...lessonsFields.Status, checked: true },
        { ...lessonsFields.SortOrder, checked: true },
      ];

      this.tabs = [
        {
          id: "AllLecturesFeedbacks",
          isPrimary: true,
          title: "כל ההיגדים",
          newEntityComponent: NewReportEntity,
          getDataAsync: this.getAllFeedbacksStatements,
          perPage: 20,
          fields,
          filters: null,
          sortField: "Id",
          sortOrder: "desc",
          checkableRows: false,
          // excelExport,
          fastSearch: true,
          fastSearchOptions: {
            propName: "Terms",
            label: "חיפוש מהיר",
          },
          sideViewEntity,
          // tableActions: (() => {
          //   let actions = [TableActions.markAsPaid, TableActions.driverAssign, TableActions.addTask];
          //   if (this.isAdmin) {
          //     actions = actions.concat([
          //       {
          //         ...TableActions.changeTransferStatusCompany,
          //         Label: "שינוי סטטוס הסבת חברה",
          //       },
          //       {
          //         ...TableActions.changeTransferStatusDriver,
          //         Label: "שינוי סטטוס הסבת נהג",
          //       },
          //       TableActions.changeReportStatus,
          //     ]);
          //     if (this.isUserProfile) {
          //       actions = actions.concat([TableActions.removeFromProfile]);
          //     }
          //   }
          //   return actions;
          // })(),
        },
      ];
    },
    addLecturesFeedbackStatement() {
      Modal.open({
        component: DialogAddLecturesFeedbackStatement,
        props: {
          store: this.$store
        },
        canCancel: false,
        hasModalCard: true,
        trapFocus: true,
      });
    },
    async onPlateNumberClick(context) {
      if (context.item.IsVehicleMine) {
        context.default();
      } else {
        const contract = await CoursesService.getReportContract(context.item.Id);
        this.$refs.entity
          .setExtraSideMenu({
            component: VehicleContractEntity,
            title: "חוזה לרכב",
            recordUrl: "contracts",
            preview: true,
            readOnly: true,
            Id: contract.data.VehicleContractId,
          })
          .show();
      }
    },
    showNewEntity() {
      this.$refs.entity.newEntityMenu();
    },
    onNewEntitySave() {
      this.$refs.entity.onNewEntityCancel();
    },
    export({ loading, query }, isPaidReportsTab) {
      loading(true);
      let currExport = "exportXsl";
      if (isPaidReportsTab) currExport = "exportPaidReportsXsl";
      CoursesService[currExport](query)
        // CoursesService.exportXsl(query)
        .then((r) => {
          this.saveFile(r.data, "יצוא דוחות ממערכת Betterway");
        })
        .finally(() => {
          loading(false);
        });
    },
    exportByMunic({ loading, query }) {
      loading(true);
      CoursesService.exportReportsXslBy(query, "Municipalities")
        .then((r) => {
          this.saveFile(r.data, "יצוא דוחות ממערכת Betterway מקובץ לפי עיריות");
        })
        .catch(() => { })
        .finally(() => {
          loading(false);
        });
    },
    exportByClient({ loading, query }) {
      loading(true);
      CoursesService.exportReportsXslBy(query, "Profiles")
        .then((r) => {
          this.saveFile(r.data, "יצוא דוחות ממערכת Betterway מקובץ לפי לקוחות");
        })
        .catch(() => { })
        .finally(() => {
          loading(false);
        });
    },
    getAllFeedbacksStatements({ ...rest }) {
      return CoursesService.getCourseFeedbacksStatements(this.course.id, rest).then((r) => ({
        data: {
          TotalResults: r.data.totalResults,
          Items: r.data.items,
        },
      }));
    },
    getPayments({ cancelToken }) {
      return CoursesService.getPayments(cancelToken.token).then((r) => ({
        data: {
          TotalResults: r.data.length,
          Items: r.data,
        },
      }));
    },
    getTotalRecords(slotProps) {
      return slotProps.tab && slotProps.tab.total && slotProps.tab.total;
    },
    saveFile(data, filename) {
      const blob = new Blob([data], { type: this.excelMimeType });
      saveAs(blob, `${filename}.xlsx`);
    },
    getFromStatusApproved(query) {
      // eslint-disable-next-line eqeqeq
      if (typeof query.StatusApproved == "boolean") {
        if (query.StatusApproved) {
          if (query.Status) {
            if (!query.Status.includes("ApproveForPayment")) {
              query.Status.push("ApproveForPayment");
            }
          } else query.Status = ["ApproveForPayment"];
        } else {
          if (query.Status) {
            query.Status = query.Status.filter(
              (item) => item !== "ApproveForPayment"
            );
          }
          query.NotStatus = ["ApproveForPayment"];
        }
        delete query.StatusApproved;
      }
      if (query.StatusApproved === null) {
        delete query.NotStatus;
        if (!query.Status) delete query.Status;
        else {
          query.Status = query.Status.filter(
            (item) => item !== "ApproveForPayment"
          );
        }
        delete query.StatusApproved;
      }
      return query;
    },
  },
  watch: {
    isReady(value) {
      if (value) {
        this.create();
      }
    },
  },
};
</script>
<style scoped lang="scss">
.link-button {
  background: #ff7a59;
  border: none;
  border-radius: 3px;
  padding: 14px 25px;
  color: white;
  cursor: pointer;
}
</style>
