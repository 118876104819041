<template>
  <div class="Report__Entity">
    <Entity ref="entity" entity-type="Report" :entity-id="lecturesTeacherId" :innerComponent="innerComponent"
      innerComponentTitle="עריכת מרצה" title="מרצה" back-button-text="רשימת יחידות לימוד"
      back-button-url="lessons" :widgets="widgets" :actions="actions" :preview="preview" :read-only="readOnly"
      :entityReady="entityReady" :activities="[]" @onFormSave="onFormSave" @onFormDiscard="onFormDiscard">
      <template #mandatory>
        <div class="Accident__Entity__Details__BaseDetails__Stage">
          <span class="ltr">מספר מזהה למרצה:</span>
          <span class="
              Accident__Entity__Details__BaseDetails__Stage__Value
              value
              has-text-weight-bold
            ">{{ lecturesTeacherId }}</span>
        </div>
        <div class="Accident__Entity__Details__BaseDetails__Stage">
          <span class="ltr">נושאים למרצה:</span>
          <span class="
              Accident__Entity__Details__BaseDetails__Stage__Value
              value
              has-text-weight-bold
            ">{{ lecturesCount || 0 }}</span>
        </div>
      </template>
    </Entity>
  </div>
</template>

<script>
// import DriverService from '@/services/DriverService';
import Entity from "@/components/Entity.vue";
import LecturesTeacherEntityDetails from "@/components/LecturesTeacherEntityDetails.vue";
// import EntityMixin from '@/mixins/EntityMixin';
import moment from "moment";
import { mapActions } from "vuex";
import { createHelpers } from "vuex-map-fields";
import CoursesService from "@/services/CoursesService";
// import WidgetService from "@/services/WidgetService";
// import VehicleService from "@/services/VehicleService";
import ArchiveService from "@/services/ArchiveService";
import DialogReportHistory from "@/components/DialogReportHistory.vue";
import {
  DialogProgrammatic as Dialog,
  ToastProgrammatic as Toast,
  ModalProgrammatic as Modal
} from "buefy";
// import FieldDateHourInline from "../components/Fields/FieldDateHourInline.vue";
// import DriverService from "../services/DriverService";

const { mapFields } = createHelpers({
  getterType: "lecturesTeacher/getField",
  mutationType: "lecturesTeacher/updateField",
});

export default {
  components: {
    Entity,
    // FieldDateHourInline,
  },
  props: ["id", "preview", "readOnly"],
  computed: {
    lecturesTeacherId() {
      return this.$route.params.id || this.id;
    },
    isLeasingCompany() {
      return this.$store.state.profile?.IsLeasingCompany;
    },
    isAdmin() {
      return this.$store.state.auth?.user?.claims?.isAdmin === "True";
    },
    widgets() {
      const widgets = [];
      return widgets;
    },
    actions() {
      return this.isAdmin
        ? [
          {
            text: "אימות דוח",
            onClick: () => {
              Dialog.confirm({
                message: "האם לבצע אימות עבור דוח זה?",
                onConfirm: () => {
                  CoursesService.scanDetailsFromMunic({
                    reportNumber: this.ReportNumber,
                  });
                  Toast.open({
                    type: "is-success",
                    message:
                      "הסריקה החלה... התהליך עשוי להמשך מספר דקות. ניתן להמשיך בעבודה",
                    duration: 10000,
                  });
                },
                confirmText: "בטוח!",
                cancelText: "בטל",
              });
            },
          },
          {
            text: "היסטוריית אירועים",
            onClick: () => {
              ArchiveService.getHistory(this.lecturesTeacherId).then((r) => {
                this.data = r.data;

                this.$buefy.modal.open({
                  component: DialogReportHistory,
                  props: {
                    data: this.data,
                  },
                  canCancel: false,
                  hasModalCard: true,
                  trapFocus: true,
                });
              });
            },
          },
        ]
        : null;
    },
    innerComponent() {
      return LecturesTeacherEntityDetails;
    },
    ...mapFields(["name", "lecturesCount"]),
  },
  filters: {
    date(value) {
      const date = moment(value, "YYYY-MM-DDThh:mm");
      return date.format("DD/MM/YYYY hh:mm");
    },
  },
  data() {
    return {
      entityReady: false,
      isEntityPage: false
    };
  },
  async created() {
    this.init();
    this.isEntityPage = !this.$route.params.id;
  },
  methods: {
    async init(callback) {
      try {
        await this.load({
          id: this.lecturesTeacherId,
        });
        this.entityReady = true;
        if (callback) {
          callback();
        }
      } catch (err) {
        console.log(err);
      }
    },
    onFormSave({ refresh, closeDialog, model, saving }) {
      saving(true);
      CoursesService.updateLecturesTeacher(this.lecturesTeacherId, {
        ...this.$store.state.lecturesTeacher,
        ...model,
      })
        .then(() => {
          // this.init(refresh);
          this.$emit('onSave');
          this.$buefy.toast.open({
            type: "is-success",
            message: "נשמר בהצלחה!",
            duration: 4000,
          });
        })
        .catch(() => {
          this.$buefy.toast.open({
            type: "is-danger",
            message: "קרתה תקלה, השמירה לא התבצעה!",
            duration: 8000,
          });
          refresh();
        })
        .finally(() => {
          saving(false);
          closeDialog();
        });
    },
    onFormDiscard({ restoreState, closeDialog }) {
      restoreState();
      closeDialog();
    },
    ...mapActions({
      load: "lecturesTeacher/loadLecturesTeacher",
    }),
  },
  // watch: {
  //   id(old, newValue) {
  //     console.log(old, newValue);
  //   },
  // },
};
</script>

<style scoped lang="scss">

.link {
    text-decoration: underline;
    cursor: pointer;
  }
.flex-between {
  display: flex;
  justify-content: space-between;

  .Accident__Entity__Details__BaseDetails__Stage__Value {
    padding-right: 0;
  }

  ::v-deep .b-tooltip {
    margin-left: 10px;
  }

  .Pay__Icon {
    border-radius: 3px;
    color: #0091ae;
    position: relative;
    right: -5px;
    font-size: 14px;

    .payment-btn {
      color: white;
      position: absolute;
      left: 20px;
      background-color: #ff7a59;
      height: 45px;
      margin: 0 10px;
      border: none;
      padding: 10px;
      cursor: pointer;

      &:hover {
        background-color: #fb6743;
      }
    }

    i {
      width: 30px;
      display: inline-block;
      vertical-align: middle;
    }
  }
}
</style>
