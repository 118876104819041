<template>
  <div class="LessonEntityDetails__Component">
    <FieldInlineText label="שם" @onChange="onChange" v-model="model.fullName" />

    <FieldInlineText type="number" label="מספר הרצאות ביום" @onChange="onChange"
      v-model="model.numberOfLecturesPerDay" />

    <FieldInlineText type="number" label="ערבים בשבוע" @onChange="onChange" v-model="model.numberOfEveningPewWeek" />


    <FieldInlineText label="הערה פנימית" @onChange="onChange" v-model="model.editorNote" />

    <b-checkbox @input="onChange" v-model="model.canDoTwoLecturesInOneDay" label="" type="is-link is-light"
      class="checkbox">
      האם יכול 2 הרצאות ביום?
    </b-checkbox>


    <b-field label="נושאים למרצה">
      <v-select @input="onChange" dir="rtl" v-model="model.teacherSubjects" :filterable="true" :clearable="true"
        :reduce="item => item.Value" :getOptionLabel="(item) => `${item.Text}`" :options="subjects" :multiple="true"
        :searchable="true" :close-on-select="false" :placeholder="'בחר אזור מהרשימה'">
      </v-select>
    </b-field>

    <b-field label="אזורים מועדפים">
      <v-select @input="onChange" dir="rtl" v-model="model.regionIds" :filterable="true" :clearable="true"
        :reduce="item => item.Value" :getOptionLabel="(item) => `${item.Text}`" :options="regions" :multiple="true"
        :searchable="true" :close-on-select="false" :placeholder="'בחר אזור מהרשימה'">
      </v-select>
    </b-field>

    <div class="checkbox-container">
      <b-switch class="ltr" @input="onChange" v-model="model.status">{{
      model.status ? "פעיל" : "לא פעיל"
    }}</b-switch>
    </div>

  </div>
</template>

<script>
import FieldInlineText from '@/components/Fields/FieldInlineText.vue';
// import FieldInlineTextArea from '@/components/Fields/FieldInlineTextArea.vue';
// import FieldInlineDate from '@/components/Fields/FieldInlineDate.vue';
// import FieldInlineDateTime from '@/components/Fields/FieldInlineDateTime.vue';
import FieldInlineSelect from '@/components/Fields/FieldInlineSelect.vue';
import CoursesService from "@/services/CoursesService";
import { mapGetters, mapMutations } from 'vuex';
import vSelect from "vue-select";
import "vue-select/src/scss/vue-select.scss";

export default {
  name: 'LessonEntityDetails',
  components: {
    FieldInlineText,
    FieldInlineSelect,
    vSelect
  },
  props: ['readOnly'],
  data() {
    return {
      model: {
        id: null,
        canDoTwoLecturesInOneDay: null,
        courseId: null,
        editorNote: null,
        fullName: null,
        imagePath: null,
        numberOfEveningPewWeek: null,
        numberOfLecturesPerDay: null,
        overallScore: null,
        userId: null,
        regionIds: null,
        teacherSubjects: null,
        status: null
      },
      regions: [],
      subjects: []
    };
  },
  computed: {
    ...mapGetters('lecturesTeacher', [
      'form',
    ]),
    courseId() {
      return this.$store.state.auth?.course.id;
    },
    changesAmount() {
      return Object.keys(this.diff(this.model, this.form)).length;
    },
  },
  created() {
    this.model = { ...this.model, ...this.form };
    this.getRegions();
    this.getSubjects();
  },
  methods: {
    diff(o1, o2) {
      const diff = Object.keys(o2).reduce((diff1, key) => {
        if (o1[key] && typeof o1[key].getMonth === 'function') {
          if (new Date(o1[key]).toString() === new Date(o2[key]).toString()) return diff1;
        }
        if (typeof o1[key] === 'number') {
          if (parseInt(o1[key], 10) === parseInt(o2[key], 10)) return diff1;
        }
        if (o1[key] && typeof o1[key] === 'object' && typeof o1[key].getMonth !== 'function') {
          if (o1[key]?.Id === o2[key]?.Id) return diff1;
        }
        if (o1[key] === o2[key]) return diff1;
        return {
          ...diff1,
          [key]: o2[key],
        };
      }, {});

      return diff;
    },
    onChange() {
      this.$emit('onFormChange', this.changesAmount);
    },
    cancel() {
      this.model = { ...this.model, ...this.form };
    },
    getRegions() {
      CoursesService.getCourseRegions(this.courseId, {
        PageSize: 999,
        IsAscending: false,
        PageNumber: 1,
        ResultType: 3
      }).then((r) =>
        this.regions = r.data.items.map((i) => ({ Value: i.id, Text: i.name }))
      );
    },
    getSubjects() {
      CoursesService.getTrackSubjects(this.$store.state.auth?.course.id, {
        PageSize: 999,
        IsAscending: false,
        PageNumber: 1,
        ResultType: 3
      }).then((r) =>
        this.subjects = r.data.items.map((i) => ({ Value: i.id, Text: i.name }))
      );
    },
    save() {
      this.$emit('onFormSave', {
        model: this.model,
        cancel: this.cancel,
        setRestorePoint: this.setRestorePoint,
      });
    },
    setRestorePoint() {
      this.storeLessonDetails(this.model);
    },
    refresh() {
      this.model = { ...this.model, ...this.form };
    },
    ...mapMutations('lecturesTeacher', [
      'storeLessonDetails',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.checkbox-container {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}
</style>
