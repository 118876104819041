var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "DialogReportPaid__Component" }, [
    _c("div", { staticClass: "modal-card" }, [
      _c("header", { staticClass: "modal-card-head" }, [
        _c("p", { staticClass: "modal-card-title" }, [_vm._v("הוספת אזור")]),
        _c("button", {
          staticClass: "delete",
          attrs: { type: "button" },
          on: {
            click: function($event) {
              return _vm.$emit("close")
            }
          }
        })
      ]),
      _c("section", { staticClass: "modal-card-body" }, [
        _c(
          "div",
          { staticClass: "DialogReportPaid__Component__Loading" },
          [
            _c(
              "b-field",
              { attrs: { label: "הזן שם לאזור" } },
              [
                _c("b-input", {
                  model: {
                    value: _vm.model.name,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "name", $$v)
                    },
                    expression: "model.name"
                  }
                })
              ],
              1
            ),
            _c(
              "b-field",
              { attrs: { label: "הזן תיאור לאזור" } },
              [
                _c("b-input", {
                  model: {
                    value: _vm.model.description,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "description", $$v)
                    },
                    expression: "model.description"
                  }
                })
              ],
              1
            ),
            _c(
              "b-field",
              { attrs: { label: "הזן סדר לאזור" } },
              [
                _c("b-input", {
                  attrs: { type: "number" },
                  model: {
                    value: _vm.model.sortOrder,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "sortOrder", $$v)
                    },
                    expression: "model.sortOrder"
                  }
                })
              ],
              1
            ),
            _c(
              "b-field",
              { attrs: { label: "סטטוס" } },
              [
                _c(
                  "b-select",
                  {
                    model: {
                      value: _vm.model.status,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "status", $$v)
                      },
                      expression: "model.status"
                    }
                  },
                  [
                    _c("option", { attrs: { value: "1" } }, [_vm._v("1")]),
                    _c("option", { attrs: { value: "0" } }, [_vm._v("0")])
                  ]
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "footer",
        { staticClass: "modal-card-foot" },
        [
          _c("b-button", {
            attrs: {
              loading: _vm.isLoading,
              label: "שמור",
              type: "is-primary"
            },
            on: { click: _vm.save }
          }),
          _c("b-button", {
            attrs: { disabled: _vm.isLoading, label: "סגור" },
            on: {
              click: function($event) {
                return _vm.$emit("close")
              }
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }