var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.exerciseName
    ? _c(
        "div",
        { attrs: { id: "exercisePrint" } },
        [
          _c(
            "button",
            {
              staticClass: "print-report-btn",
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.print($event)
                }
              }
            },
            [_vm._v("הדפסה")]
          ),
          _c("div", { staticClass: "exercise-title" }, [
            _c("div", { staticStyle: { "font-size": "2em" } }, [
              _vm._v("תרגול: "),
              _c("span", { staticStyle: { "font-weight": "bold" } }, [
                _vm._v(_vm._s(_vm.exerciseName))
              ])
            ]),
            _c("div", { staticStyle: { "font-size": "2em" } }, [
              _vm._v("מתוך: " + _vm._s(_vm.categoryName || ""))
            ])
          ]),
          _vm._l(_vm.questions, function(question, index) {
            return _vm.questions
              ? _c("div", { key: question.id }, [
                  _c(
                    "div",
                    { staticClass: "question" },
                    [
                      _c("b", [_vm._v(_vm._s(index + 1) + ".")]),
                      _vm._v(" "),
                      _c("span", {
                        domProps: { innerHTML: _vm._s(question.question) }
                      }),
                      _vm._l(question.answers, function(answer) {
                        return _c(
                          "div",
                          { staticClass: "answer" },
                          [
                            _c("span", [
                              _c("b", [
                                _vm._v(
                                  _vm._s(_vm._f("letterize")(answer.sortOrder))
                                )
                              ])
                            ]),
                            _c("span", {
                              domProps: { innerHTML: _vm._s(answer.answer) }
                            }),
                            answer.isCorrect
                              ? _c("b-icon", {
                                  staticStyle: { color: "green" },
                                  attrs: { icon: "check" }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      }),
                      question.detailedAnswer
                        ? _c("div", { staticClass: "questionText" }, [
                            _c("div", [_vm._v("תשובה מפורטת")]),
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(question.detailedAnswer)
                              }
                            })
                          ])
                        : _vm._e()
                    ],
                    2
                  )
                ])
              : _vm._e()
          }),
          _c("div", { staticClass: "signature" }, [
            _vm._v("הודפס ממערכת הסימולטור - המתמחה מבית נבו")
          ])
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }