<template>
  <div class="LessonEntityDetails__Component">
    <FieldInlineDateTime label="זמן התחלת ההרצאה" @onChange="onChange" :hide-hover="true" position="is-bottom-left"
      v-model="model.startTime" />
    <FieldInlineDateTime label="זמן סיום ההרצאה" @onChange="onChange" :hide-hover="true" position="is-bottom-left"
      v-model="model.endTime" />

    <FieldInlineSelect label="מרצה" @onChange="onChange" :filterable="true" :clearable="true"
      :optionsAsync="getTeachers" v-model="model.lecturesTeachersId" />

    <FieldInlineSelect label="אולם" @onChange="onChange" :filterable="true" :clearable="true" :optionsAsync="getHalls"
      v-model="model.lecturesHallsId" />

    <FieldInlineText type="number" label="קיבולת" @onChange="onChange" v-model="model.capacity" />

    <FieldInlineText type="number" label="קו צהוב" @onChange="onChange" v-model="model.capacityYellowLine" />

    <FieldInlineText label="הערה לתלמיד" @onChange="onChange" v-model="model.userNote" />

    <FieldInlineText label="הערה פנימית" @onChange="onChange" v-model="model.editorNote" />

    <ckeditor :editor="editor" placeholder="'הנחיות'" @input="onChange" v-model="model.instractionsNote" :config="editorConfig">
    </ckeditor>

    <div class="checkbox-container">
      <b-switch class="ltr" @input="onChange" v-model="model.status">{{
      model.status ? "פעיל" : "לא פעיל"
        }}</b-switch>
    </div>

  </div>
</template>

<script>
import FieldInlineText from '@/components/Fields/FieldInlineText.vue';
// import FieldInlineTextArea from '@/components/Fields/FieldInlineTextArea.vue';
// import FieldInlineDate from '@/components/Fields/FieldInlineDate.vue';
import FieldInlineDateTime from '@/components/Fields/FieldInlineDateTime.vue';
import FieldInlineSelect from '@/components/Fields/FieldInlineSelect.vue';
import CoursesService from '@/services/CoursesService';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { mapGetters, mapMutations } from 'vuex';
import vSelect from "vue-select";
import "vue-select/src/scss/vue-select.scss";

export default {
  name: 'LessonEntityDetails',
  components: {
    FieldInlineText,
    FieldInlineSelect,
    FieldInlineDateTime,
    vSelect,
    ClassicEditor
  },
  props: ['readOnly'],
  data() {
    return {
      model: {
        address: null,
        capacity: null,
        capacityYellowLine: null,
        count: null,
        createdUserId: null,
        dateCreated: null,
        day: null,
        editorNote: null,
        endTime: null,
        hallName: null,
        id: null,
        instractionsNote: null,
        lecturesHallsId: null,
        lecturesLearningTrackSubjectsId: null,
        lecturesTeachersId: null,
        startTime: null,
        status: null,
        subjectName: null,
        teacherName: null,
        trackName: null,
        userNote: null
      },
      editor: ClassicEditor,
      editorConfig: {
        // The configuration of the editor.
        language: {
          ui: 'he',
        }
      },
      regions: []
    };
  },
  computed: {
    ...mapGetters('lecture', [
      'form',
    ]),
    courseId() {
      return this.$store.state.auth?.course.id;
    },
    changesAmount() {
      return Object.keys(this.diff(this.model, this.form)).length;
    },
  },
  created() {
    this.model = { ...this.model, ...this.form };
  },
  methods: {
    diff(o1, o2) {
      const diff = Object.keys(o2).reduce((diff1, key) => {
        if (o1[key] && typeof o1[key].getMonth === 'function') {
          if (new Date(o1[key]).toString() === new Date(o2[key]).toString()) return diff1;
        }
        if (typeof o1[key] === 'number') {
          if (parseInt(o1[key], 10) === parseInt(o2[key], 10)) return diff1;
        }
        if (o1[key] && typeof o1[key] === 'object' && typeof o1[key].getMonth !== 'function') {
          if (o1[key]?.Id === o2[key]?.Id) return diff1;
        }
        if (o1[key] === o2[key]) return diff1;
        return {
          ...diff1,
          [key]: o2[key],
        };
      }, {});

      return diff;
    },
    onChange() {
      this.$emit('onFormChange', this.changesAmount);
    },
    cancel() {
      this.model = { ...this.model, ...this.form };
    },
    save() {
      this.$emit('onFormSave', {
        model: this.model,
        cancel: this.cancel,
        setRestorePoint: this.setRestorePoint,
      });
    },
    getTeachers() {
      return () =>
        CoursesService.getCourseTeachers(this.courseId, {
          PageSize: 999,
          IsAscending: false,
          PageNumber: 1,
          ResultType: 3
        }).then((r) =>
          r.data.items.map((i) => ({ Value: i.id, Text: i.fullName }))
        );
    },
    getHalls() {
      return () =>
        CoursesService.getCourseHalls(this.courseId, {
          PageSize: 999,
          IsAscending: false,
          PageNumber: 1,
          ResultType: 3
        }).then((r) =>
          r.data.items.map((i) => ({ Value: i.id, Text: i.name }))
        );
    },
    setRestorePoint() {
      this.storeLessonDetails(this.model);
    },
    refresh() {
      this.model = { ...this.model, ...this.form };
    },
    ...mapMutations('lecture', [
      'storeLessonDetails',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.checkbox-container {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}
</style>
