<template>
  <div v-if="isReady" class="Reports__View">
    <EntityListLayout v-if="tabs.length" :tabs="tabs" ref="entity" @onNewEntitySave="onNewEntitySave">
      <template #title>נתוני תשובות לתרגולים בקורס {{ course.name }}</template>
      <!-- <template #button>
        <a @click="addGroup" class="link-button">
          הוספת קבוצה
        </a>
      </template> -->
      <template v-slot:counter="slotProps">
        סה"כ <b>{{ getTotalRecords(slotProps) }} </b>
      </template>
    </EntityListLayout>
  </div>
</template>
<script>
import LessonService from "@/services/LessonService";
import EntityListLayout from "@/components/EntityListLayout.vue";
import ExerciseAnswerEntity from "@/views/ExerciseAnswerEntity.vue";
import {
  ModalProgrammatic as Modal,
} from 'buefy';
import VehicleContractEntity from "@/views/VehicleContractEntity.vue";
import TableActions from "@/services/TableActionsService";
import FieldService from "@/services/FieldService";
import FilterService from "@/services/FilterService";
import NewReportEntity from "@/components/Entities/NewReportEntity.vue";
import { saveAs } from "file-saver";

export default {
  name: "Reports",
  data() {
    return {
      tabs: [],
    };
  },
  components: {
    EntityListLayout,
  },
  computed: {
    isUserProfile() {
      return this.$store.state.auth.profileId;
    },
    isAdmin() {
      return this.$store.state.auth?.user?.claims?.isAdmin === "True";
    },
    tabName() {
      return this.selectedTab && this.selectedTab.title;
    },
    courseId() {
      return this.$store.state.auth.course.id;
    },
    course() {
      return this.$store.state.auth?.course;
    },
    excelMimeType() {
      return "application/vnd.ms-excel";
    },
    isLeasingCompany() {
      return this.$store.state.profile?.IsLeasingCompany;
    },
    isEndUser() {
      return !this.isLeasingCompany;
    },
    isReady() {
      return this.isLeasingCompany !== null;
    },
  },
  created() {
    if (this.isReady) {
      this.create();
    }
  },
  methods: {
    create() {
      const lessonsFields = FieldService.list("exerciseAnswers");
      const reportsfilters = FilterService.list();

      const sideViewEntity = {
        component: ExerciseAnswerEntity,
        preview: true,
        readOnly: !this.isAdmin,
        title: "נתוני תרגיל",
        recordUrl: "lessons",
        hideRecord: true,
        recordTitle: "הצג יחידת לימוד"
      };

      // const excelExport = [
      //   {
      //     title: "יצוא טבלה",
      //     action: this.export,
      //   },
      //   {
      //     title: "יצוא מקובץ לפי עיריות",
      //     action: this.exportByMunic,
      //   },
      //   {
      //     title: "יצוא מקובץ לפי לקוח",
      //     action: this.exportByClient,
      //   },
      // ];
      const fields = [
        // { ...lessonsFields.Id, checked: true },
        { ...lessonsFields.UserName, checked: true },
        { ...lessonsFields.ExerciseName, checked: true },
        { ...lessonsFields.ReportUrl, checked: true },
        { ...lessonsFields.Grade, checked: true },
        { ...lessonsFields.MadadTakinEditor, checked: true },
        { ...lessonsFields.StarPercent, checked: true },
        { ...lessonsFields.StarGrade, checked: true },
        { ...lessonsFields.GrayPercent, checked: true },
        { ...lessonsFields.GrayGrade, checked: true },
        { ...lessonsFields.Ahuzon, checked: true },
        { ...lessonsFields.EndDateTime, checked: true },
        { ...lessonsFields.ExerciseQuestionsLink, checked: true },
        { ...lessonsFields.FieldExerciseAnswers, checked: true },
        { ...lessonsFields.WasResearched, checked: true },
        { ...lessonsFields.FieldExerciseReset, checked: true },
      ];

      this.tabs = [
        {
          id: "AllExerciseAnswers",
          isPrimary: true,
          title: "כל התשובות לתרגולים",
          newEntityComponent: NewReportEntity,
          getDataAsync: this.getExerciseAnswers,
          perPage: 20,
          fields,
          filters: [
          { ...reportsfilters.Users, selected: this.$route.params.UserIds },
          { ...reportsfilters.Exercises, selected: this.$route.params.ExerciseIds },
          { ...reportsfilters.Groups, selected: this.$route.params.GroupIds },
          ],
          sortField: "Id",
          sortOrder: "desc",
          checkableRows: false,
          // excelExport,
          fastSearch: true,
          fastSearchOptions: {
            propName: "Terms",
            label: "חיפוש מהיר",
          },
          sideViewEntity,
          // tableActions: (() => {
          //   let actions = [TableActions.deleteGroups, TableActions.assignGroupsToLessons, TableActions.assignGroupsToStudents];
          //   if (this.isAdmin) {
          //     actions = actions.concat([
          //       {
          //         ...TableActions.changeTransferStatusCompany,
          //         Label: "שינוי סטטוס הסבת חברה",
          //       },
          //       {
          //         ...TableActions.changeTransferStatusDriver,
          //         Label: "שינוי סטטוס הסבת נהג",
          //       },
          //       TableActions.changeReportStatus,
          //     ]);
          //     if (this.isUserProfile) {
          //       actions = actions.concat([TableActions.removeFromProfile]);
          //     }
          //   }
          //   return actions;
          // })(),
        },
      ];
    },
    // addGroup() {
    //   Modal.open({
    //     component: DialogAddGroup,
    //     props: {
    //       store: this.$store
    //     },
    //     canCancel: false,
    //     hasModalCard: true,
    //     trapFocus: true,
    //   });
    // },
    async onPlateNumberClick(context) {
      if (context.item.IsVehicleMine) {
        context.default();
      } else {
        const contract = await LessonService.getReportContract(context.item.Id);
        this.$refs.entity
          .setExtraSideMenu({
            component: VehicleContractEntity,
            title: "חוזה לרכב",
            recordUrl: "contracts",
            preview: true,
            readOnly: true,
            Id: contract.data.VehicleContractId,
          })
          .show();
      }
    },
    showNewEntity() {
      this.$refs.entity.newEntityMenu();
    },
    onNewEntitySave() {
      this.$refs.entity.onNewEntityCancel();
    },
    export({ loading, query }, isPaidReportsTab) {
      loading(true);
      let currExport = "exportXsl";
      if (isPaidReportsTab) currExport = "exportPaidReportsXsl";
      LessonService[currExport](query)
        // LessonService.exportXsl(query)
        .then((r) => {
          this.saveFile(r.data, "יצוא דוחות ממערכת Betterway");
        })
        .finally(() => {
          loading(false);
        });
    },
    exportByMunic({ loading, query }) {
      loading(true);
      LessonService.exportReportsXslBy(query, "Municipalities")
        .then((r) => {
          this.saveFile(r.data, "יצוא דוחות ממערכת Betterway מקובץ לפי עיריות");
        })
        .catch(() => { })
        .finally(() => {
          loading(false);
        });
    },
    exportByClient({ loading, query }) {
      loading(true);
      LessonService.exportReportsXslBy(query, "Profiles")
        .then((r) => {
          this.saveFile(r.data, "יצוא דוחות ממערכת Betterway מקובץ לפי לקוחות");
        })
        .catch(() => { })
        .finally(() => {
          loading(false);
        });
    },
    getExerciseAnswers({ ...rest }) {
      return LessonService.getExerciseAnswers(this.courseId, rest, rest.cancelToken.token).then((r) => ({
        data: {
          TotalResults: r.data.totalResults,
          Items: r.data.items,
        },
      }));
    },
    getPayments({ cancelToken }) {
      return LessonService.getPayments(cancelToken.token).then((r) => ({
        data: {
          TotalResults: r.data.length,
          Items: r.data,
        },
      }));
    },
    getTotalRecords(slotProps) {
      return slotProps.tab && slotProps.tab.total && slotProps.tab.total;
    },
    saveFile(data, filename) {
      const blob = new Blob([data], { type: this.excelMimeType });
      saveAs(blob, `${filename}.xlsx`);
    },
    getFromStatusApproved(query) {
      // eslint-disable-next-line eqeqeq
      if (typeof query.StatusApproved == "boolean") {
        if (query.StatusApproved) {
          if (query.Status) {
            if (!query.Status.includes("ApproveForPayment")) {
              query.Status.push("ApproveForPayment");
            }
          } else query.Status = ["ApproveForPayment"];
        } else {
          if (query.Status) {
            query.Status = query.Status.filter(
              (item) => item !== "ApproveForPayment"
            );
          }
          query.NotStatus = ["ApproveForPayment"];
        }
        delete query.StatusApproved;
      }
      if (query.StatusApproved === null) {
        delete query.NotStatus;
        if (!query.Status) delete query.Status;
        else {
          query.Status = query.Status.filter(
            (item) => item !== "ApproveForPayment"
          );
        }
        delete query.StatusApproved;
      }
      return query;
    },
  },
  watch: {
    isReady(value) {
      if (value) {
        this.create();
      }
    },
  },
};
</script>
<style scoped lang="scss">
.link-button {
  background: #ff7a59;
  border: none;
  border-radius: 3px;
  padding: 14px 25px;
  color: white;
  cursor: pointer;
}
</style>
