var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "LessonEntityDetails__Component" }, [
    _c(
      "div",
      { staticClass: "Accident__Entity__Details__BaseDetails__Stage" },
      [
        _c("span", { staticClass: "ltr" }, [_vm._v("טווח ציונים תקין:")]),
        _c(
          "span",
          {
            staticClass:
              "\n            Accident__Entity__Details__BaseDetails__Stage__Value\n            value\n            has-text-weight-bold\n          "
          },
          [_vm._v(_vm._s(_vm.model.madadTakinEditor))]
        )
      ]
    ),
    _c(
      "div",
      { staticClass: "Accident__Entity__Details__BaseDetails__Stage" },
      [
        _c("span", { staticClass: "ltr" }, [_vm._v("ציון:")]),
        _c(
          "span",
          {
            staticClass:
              "\n            Accident__Entity__Details__BaseDetails__Stage__Value\n            value\n            has-text-weight-bold\n          "
          },
          [_vm._v(_vm._s(_vm.model.grade))]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }