var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isReady
    ? _c(
        "div",
        { staticClass: "Reports__View" },
        [
          _vm.tabs.length
            ? _c("EntityListLayout", {
                ref: "entity",
                attrs: { tabs: _vm.tabs },
                on: { onNewEntitySave: _vm.onNewEntitySave },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "title",
                      fn: function() {
                        return [
                          _vm._v(
                            "נתוני תשובות לתרגולים בקורס " +
                              _vm._s(_vm.course.name)
                          )
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "counter",
                      fn: function(slotProps) {
                        return [
                          _vm._v(' סה"כ '),
                          _c("b", [
                            _vm._v(_vm._s(_vm.getTotalRecords(slotProps)) + " ")
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  950583802
                )
              })
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }