<template>
  <div class="LessonEntityDetails__Component">
    <FieldInlineText label="שם" @onChange="onChange" v-model="model.name" />

    <FieldInlineText label="הערה פנימית" @onChange="onChange" v-model="model.editorNote" />

    <FieldInlineText label="הערה לתלמיד" @onChange="onChange" v-model="model.userNote" />

    <FieldInlineDateTime label="זמין מ" @onChange="onChange" position="is-bottom-left"
      v-model="model.availableFrom" />

    <FieldInlineDateTime label="זמין עד" @onChange="onChange" position="is-bottom-left"
      v-model="model.availableUntil" />

    <FieldInlineText type="number" label="סדר" @onChange="onChange" v-model="model.sortOrder" />

    <div class="checkbox-container">
      <b-switch class="ltr" @input="onChange" v-model="model.status">{{
      model.status ? "פעיל" : "לא פעיל"
    }}</b-switch>
    </div>

  </div>
</template>

<script>
// import FieldInlineTextArea from '@/components/Fields/FieldInlineTextArea.vue';
// import FieldInlineDate from '@/components/Fields/FieldInlineDate.vue';
import FieldInlineDateTime from '@/components/Fields/FieldInlineDateTime.vue';
import FieldInlineText from '@/components/Fields/FieldInlineText.vue';
import FieldInlineSelect from '@/components/Fields/FieldInlineSelect.vue';
import { mapGetters, mapMutations } from 'vuex';
import CoursesService from '../services/CoursesService';
import LessonService from '../services/LessonService';

export default {
  name: 'LessonEntityDetails',
  components: {
    FieldInlineDateTime,
    FieldInlineText,
    FieldInlineSelect,
    // FieldInlineTextArea,
  },
  props: ['readOnly'],
  data() {
    return {
      model: {
        id: null,
        name: null,
        availableFrom: null,
        availableUntil: null,
        editorNote: null,
        sortOrder: null,
        userNote: null,
        status: null,
      },
    };
  },
  computed: {
    ...mapGetters('lectureTrackSubject', [
      'form',
    ]),
    courseId() {
      return this.$store.state.auth?.course.id;
    },
    changesAmount() {
      return Object.keys(this.diff(this.model, this.form)).length;
    },
  },
  created() {
    this.model = { ...this.model, ...this.form };
  },
  methods: {
    diff(o1, o2) {
      const diff = Object.keys(o2).reduce((diff1, key) => {
        if (o1[key] && typeof o1[key].getMonth === 'function') {
          if (new Date(o1[key]).toString() === new Date(o2[key]).toString()) return diff1;
        }
        if (typeof o1[key] === 'number') {
          if (parseInt(o1[key], 10) === parseInt(o2[key], 10)) return diff1;
        }
        if (o1[key] && typeof o1[key] === 'object' && typeof o1[key].getMonth !== 'function') {
          if (o1[key]?.Id === o2[key]?.Id) return diff1;
        }
        if (o1[key] === o2[key]) return diff1;
        return {
          ...diff1,
          [key]: o2[key],
        };
      }, {});

      return diff;
    },
    onChange() {
      this.$emit('onFormChange', this.changesAmount);
    },
    getCategories() {
      return () =>
        CoursesService.getCategories().then((r) =>
          r.data.map((i) => ({ Value: i.id, Text: i.name }))
        );
    },
    getGroups() {
      return () =>
        LessonService.getGroups(this.courseId, {
          pageSize: 999
        }).then((r) =>
          r.data.items.map((i) => ({ Value: i.id, Text: i.name }))
        );
    },
    getVerdictCollections() {
      return () =>
        CoursesService.getVerdictCollections().then((r) =>
          r.data.map((i) => ({ Value: i.id, Text: i.name }))
        );
    },
    getLaws() {
      return () =>
        CoursesService.getLawCollections().then((r) =>
          r.data.map((i) => ({ Value: i.id, Text: i.name }))
        );
    },
    cancel() {
      this.model = { ...this.model, ...this.form };
    },
    save() {
      this.$emit('onFormSave', {
        model: this.model,
        cancel: this.cancel,
        setRestorePoint: this.setRestorePoint,
      });
    },
    setRestorePoint() {
      this.storeLessonDetails(this.model);
    },
    refresh() {
      this.model = { ...this.model, ...this.form };
    },
    ...mapMutations('lectureTrackSubject', [
      'storeLessonDetails',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.checkbox-container {
  // display: flex;
  gap: 10px;
  margin-top: 10px;
}
</style>
