var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "LessonEntityDetails__Component" },
    [
      _c("FieldInlineText", {
        attrs: { label: "שם" },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.fullName,
          callback: function($$v) {
            _vm.$set(_vm.model, "fullName", $$v)
          },
          expression: "model.fullName"
        }
      }),
      _c("FieldInlineText", {
        attrs: { type: "number", label: "מספר הרצאות ביום" },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.numberOfLecturesPerDay,
          callback: function($$v) {
            _vm.$set(_vm.model, "numberOfLecturesPerDay", $$v)
          },
          expression: "model.numberOfLecturesPerDay"
        }
      }),
      _c("FieldInlineText", {
        attrs: { type: "number", label: "ערבים בשבוע" },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.numberOfEveningPewWeek,
          callback: function($$v) {
            _vm.$set(_vm.model, "numberOfEveningPewWeek", $$v)
          },
          expression: "model.numberOfEveningPewWeek"
        }
      }),
      _c("FieldInlineText", {
        attrs: { label: "הערה פנימית" },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.editorNote,
          callback: function($$v) {
            _vm.$set(_vm.model, "editorNote", $$v)
          },
          expression: "model.editorNote"
        }
      }),
      _c(
        "b-checkbox",
        {
          staticClass: "checkbox",
          attrs: { label: "", type: "is-link is-light" },
          on: { input: _vm.onChange },
          model: {
            value: _vm.model.canDoTwoLecturesInOneDay,
            callback: function($$v) {
              _vm.$set(_vm.model, "canDoTwoLecturesInOneDay", $$v)
            },
            expression: "model.canDoTwoLecturesInOneDay"
          }
        },
        [_vm._v(" האם יכול 2 הרצאות ביום? ")]
      ),
      _c(
        "b-field",
        { attrs: { label: "נושאים למרצה" } },
        [
          _c("v-select", {
            attrs: {
              dir: "rtl",
              filterable: true,
              clearable: true,
              reduce: function(item) {
                return item.Value
              },
              getOptionLabel: function(item) {
                return "" + item.Text
              },
              options: _vm.subjects,
              multiple: true,
              searchable: true,
              "close-on-select": false,
              placeholder: "בחר אזור מהרשימה"
            },
            on: { input: _vm.onChange },
            model: {
              value: _vm.model.teacherSubjects,
              callback: function($$v) {
                _vm.$set(_vm.model, "teacherSubjects", $$v)
              },
              expression: "model.teacherSubjects"
            }
          })
        ],
        1
      ),
      _c(
        "b-field",
        { attrs: { label: "אזורים מועדפים" } },
        [
          _c("v-select", {
            attrs: {
              dir: "rtl",
              filterable: true,
              clearable: true,
              reduce: function(item) {
                return item.Value
              },
              getOptionLabel: function(item) {
                return "" + item.Text
              },
              options: _vm.regions,
              multiple: true,
              searchable: true,
              "close-on-select": false,
              placeholder: "בחר אזור מהרשימה"
            },
            on: { input: _vm.onChange },
            model: {
              value: _vm.model.regionIds,
              callback: function($$v) {
                _vm.$set(_vm.model, "regionIds", $$v)
              },
              expression: "model.regionIds"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "checkbox-container" },
        [
          _c(
            "b-switch",
            {
              staticClass: "ltr",
              on: { input: _vm.onChange },
              model: {
                value: _vm.model.status,
                callback: function($$v) {
                  _vm.$set(_vm.model, "status", $$v)
                },
                expression: "model.status"
              }
            },
            [_vm._v(_vm._s(_vm.model.status ? "פעיל" : "לא פעיל"))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }