var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "LessonEntityDetails__Component" },
    [
      _c("FieldInlineText", {
        attrs: { label: "שם" },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.name,
          callback: function($$v) {
            _vm.$set(_vm.model, "name", $$v)
          },
          expression: "model.name"
        }
      }),
      _c("FieldInlineSelect", {
        attrs: {
          label: "אזור",
          filterable: true,
          clearable: true,
          optionsAsync: _vm.getRegions
        },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.lecturesRegionsId,
          callback: function($$v) {
            _vm.$set(_vm.model, "lecturesRegionsId", $$v)
          },
          expression: "model.lecturesRegionsId"
        }
      }),
      _c("FieldInlineText", {
        attrs: { label: "עיר" },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.city,
          callback: function($$v) {
            _vm.$set(_vm.model, "city", $$v)
          },
          expression: "model.city"
        }
      }),
      _c("FieldInlineText", {
        attrs: { label: "כתובת" },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.address,
          callback: function($$v) {
            _vm.$set(_vm.model, "address", $$v)
          },
          expression: "model.address"
        }
      }),
      _c("FieldInlineText", {
        attrs: { type: "number", label: "קיבולת" },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.capacity,
          callback: function($$v) {
            _vm.$set(_vm.model, "capacity", $$v)
          },
          expression: "model.capacity"
        }
      }),
      _c("FieldInlineText", {
        attrs: { type: "number", label: "קו צהוב" },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.capacityYellowLine,
          callback: function($$v) {
            _vm.$set(_vm.model, "capacityYellowLine", $$v)
          },
          expression: "model.capacityYellowLine"
        }
      }),
      _c("FieldInlineText", {
        attrs: { label: "הערה לתלמיד" },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.userNote,
          callback: function($$v) {
            _vm.$set(_vm.model, "userNote", $$v)
          },
          expression: "model.userNote"
        }
      }),
      _c("FieldInlineText", {
        attrs: { label: "הערה פנימית" },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.editorNode,
          callback: function($$v) {
            _vm.$set(_vm.model, "editorNode", $$v)
          },
          expression: "model.editorNode"
        }
      }),
      _c("FieldInlineText", {
        attrs: { label: "סוגי שולחנות" },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.tablesTypes,
          callback: function($$v) {
            _vm.$set(_vm.model, "tablesTypes", $$v)
          },
          expression: "model.tablesTypes"
        }
      }),
      _c("FieldInlineText", {
        directives: [
          {
            name: "mask",
            rawName: "v-mask",
            value: "##:##:##",
            expression: "'##:##:##'"
          }
        ],
        attrs: { label: "פתוח משעה" },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.openFrom,
          callback: function($$v) {
            _vm.$set(_vm.model, "openFrom", $$v)
          },
          expression: "model.openFrom"
        }
      }),
      _c("FieldInlineText", {
        directives: [
          {
            name: "mask",
            rawName: "v-mask",
            value: "##:##:##",
            expression: "'##:##:##'"
          }
        ],
        attrs: { label: "פתוח עד שעה" },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.openUntil,
          callback: function($$v) {
            _vm.$set(_vm.model, "openUntil", $$v)
          },
          expression: "model.openUntil"
        }
      }),
      _c("FieldInlineText", {
        attrs: { label: "איש קשר" },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.contactInfo,
          callback: function($$v) {
            _vm.$set(_vm.model, "contactInfo", $$v)
          },
          expression: "model.contactInfo"
        }
      }),
      _c("FieldInlineText", {
        attrs: { label: "לינק למפות גוגל" },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.googleMapLink,
          callback: function($$v) {
            _vm.$set(_vm.model, "googleMapLink", $$v)
          },
          expression: "model.googleMapLink"
        }
      }),
      _c("FieldInlineText", {
        attrs: { label: "לינק למפות WAZE" },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.wazelink,
          callback: function($$v) {
            _vm.$set(_vm.model, "wazelink", $$v)
          },
          expression: "model.wazelink"
        }
      }),
      _c(
        "b-checkbox",
        {
          staticClass: "checkbox",
          staticStyle: { gap: "10px" },
          attrs: { label: "", type: "is-link is-light" },
          on: { input: _vm.onChange },
          model: {
            value: _vm.model.refreshments,
            callback: function($$v) {
              _vm.$set(_vm.model, "refreshments", $$v)
            },
            expression: "model.refreshments"
          }
        },
        [_vm._v(" כיבוד ")]
      ),
      _c(
        "b-checkbox",
        {
          staticClass: "checkbox",
          staticStyle: { gap: "10px" },
          attrs: { label: "", type: "is-link is-light" },
          on: { input: _vm.onChange },
          model: {
            value: _vm.model.parkingForStudents,
            callback: function($$v) {
              _vm.$set(_vm.model, "parkingForStudents", $$v)
            },
            expression: "model.parkingForStudents"
          }
        },
        [_vm._v(" חנייה לתלמידים ")]
      ),
      _c(
        "b-checkbox",
        {
          staticClass: "checkbox",
          staticStyle: { gap: "10px" },
          attrs: { label: "", type: "is-link is-light" },
          on: { input: _vm.onChange },
          model: {
            value: _vm.model.parkingForTeacher,
            callback: function($$v) {
              _vm.$set(_vm.model, "parkingForTeacher", $$v)
            },
            expression: "model.parkingForTeacher"
          }
        },
        [_vm._v(" חנייה למרצה ")]
      ),
      _c(
        "b-checkbox",
        {
          staticClass: "checkbox",
          staticStyle: { gap: "10px" },
          attrs: { label: "", type: "is-link is-light" },
          on: { input: _vm.onChange },
          model: {
            value: _vm.model.airConditioningControl,
            callback: function($$v) {
              _vm.$set(_vm.model, "airConditioningControl", $$v)
            },
            expression: "model.airConditioningControl"
          }
        },
        [_vm._v(" שליטה במיזוג ")]
      ),
      _c(
        "b-checkbox",
        {
          staticClass: "checkbox",
          staticStyle: { gap: "10px" },
          attrs: { label: "", type: "is-link is-light" },
          on: { input: _vm.onChange },
          model: {
            value: _vm.model.accessForDisabled,
            callback: function($$v) {
              _vm.$set(_vm.model, "accessForDisabled", $$v)
            },
            expression: "model.accessForDisabled"
          }
        },
        [_vm._v(" גישה לנכים ")]
      ),
      _c(
        "b-checkbox",
        {
          staticClass: "checkbox",
          staticStyle: { gap: "10px" },
          attrs: { label: "", type: "is-link is-light" },
          on: { input: _vm.onChange },
          model: {
            value: _vm.model.cafeteria,
            callback: function($$v) {
              _vm.$set(_vm.model, "cafeteria", $$v)
            },
            expression: "model.cafeteria"
          }
        },
        [_vm._v(" קפיטריה ")]
      ),
      _c(
        "b-checkbox",
        {
          staticClass: "checkbox",
          staticStyle: { gap: "10px" },
          attrs: { label: "", type: "is-link is-light" },
          on: { input: _vm.onChange },
          model: {
            value: _vm.model.whiteboard,
            callback: function($$v) {
              _vm.$set(_vm.model, "whiteboard", $$v)
            },
            expression: "model.whiteboard"
          }
        },
        [_vm._v(" לוח מחיק ")]
      ),
      _c(
        "b-checkbox",
        {
          staticClass: "checkbox",
          staticStyle: { gap: "10px" },
          attrs: { label: "", type: "is-link is-light" },
          on: { input: _vm.onChange },
          model: {
            value: _vm.model.computer,
            callback: function($$v) {
              _vm.$set(_vm.model, "computer", $$v)
            },
            expression: "model.computer"
          }
        },
        [_vm._v(" מחשב ")]
      ),
      _c(
        "b-checkbox",
        {
          staticClass: "checkbox",
          staticStyle: { gap: "10px" },
          attrs: { label: "", type: "is-link is-light" },
          on: { input: _vm.onChange },
          model: {
            value: _vm.model.soundSystem,
            callback: function($$v) {
              _vm.$set(_vm.model, "soundSystem", $$v)
            },
            expression: "model.soundSystem"
          }
        },
        [_vm._v(" מערכת הגברה ")]
      ),
      _c(
        "b-checkbox",
        {
          staticClass: "checkbox",
          staticStyle: { gap: "10px" },
          attrs: { label: "", type: "is-link is-light" },
          on: { input: _vm.onChange },
          model: {
            value: _vm.model.projector,
            callback: function($$v) {
              _vm.$set(_vm.model, "projector", $$v)
            },
            expression: "model.projector"
          }
        },
        [_vm._v(" מקרן ")]
      ),
      _c(
        "div",
        { staticClass: "checkbox-container" },
        [
          _c(
            "b-switch",
            {
              staticClass: "ltr",
              on: { input: _vm.onChange },
              model: {
                value: _vm.model.status,
                callback: function($$v) {
                  _vm.$set(_vm.model, "status", $$v)
                },
                expression: "model.status"
              }
            },
            [_vm._v(_vm._s(_vm.model.status ? "פעיל" : "לא פעיל"))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }