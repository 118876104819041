<template>
  <div class="LessonEntityDetails__Component">
    <FieldInlineText label="שם" @onChange="onChange" v-model="model.name" />

    <FieldInlineText label="תיאור" @onChange="onChange" v-model="model.description" />

    <FieldInlineSelect label="סוג" @onChange="onChange" :filterable="false" :clearable="false"
      :options="[{ Value: 1, Text: 'בחירה מסולם' }, { Value: 2, Text: 'טקסט חופשי' }]" v-model="model.type" />

    <FieldInlineText type="number" label="סדר" @onChange="onChange" v-model="model.sortOrder" />

    <div class="checkbox-container">
      <b-switch class="ltr" @input="onChange" v-model="model.status">{{
      model.status ? "פעיל" : "לא פעיל"
    }}</b-switch>
    </div>

  </div>
</template>

<script>
import FieldInlineText from '@/components/Fields/FieldInlineText.vue';
// import FieldInlineTextArea from '@/components/Fields/FieldInlineTextArea.vue';
// import FieldInlineDate from '@/components/Fields/FieldInlineDate.vue';
// import FieldInlineDateTime from '@/components/Fields/FieldInlineDateTime.vue';
import FieldInlineSelect from '@/components/Fields/FieldInlineSelect.vue';
import CoursesService from "@/services/CoursesService";
import { mapGetters, mapMutations } from 'vuex';
import vSelect from "vue-select";
import "vue-select/src/scss/vue-select.scss";

export default {
  name: 'LessonEntityDetails',
  components: {
    FieldInlineText,
    FieldInlineSelect,
    vSelect
  },
  props: ['readOnly'],
  data() {
    return {
      model: {
        id: null,
        name: null,
        description: null,
        type: null,
        status: null,
        sortOrder: null,
      },
      regions: []
    };
  },
  computed: {
    ...mapGetters('lecturesFeedbackStatement', [
      'form',
    ]),
    courseId() {
      return this.$store.state.auth?.course.id;
    },
    changesAmount() {
      return Object.keys(this.diff(this.model, this.form)).length;
    },
  },
  created() {
    this.model = { ...this.model, ...this.form };
  },
  methods: {
    diff(o1, o2) {
      const diff = Object.keys(o2).reduce((diff1, key) => {
        if (o1[key] && typeof o1[key].getMonth === 'function') {
          if (new Date(o1[key]).toString() === new Date(o2[key]).toString()) return diff1;
        }
        if (typeof o1[key] === 'number') {
          if (parseInt(o1[key], 10) === parseInt(o2[key], 10)) return diff1;
        }
        if (o1[key] && typeof o1[key] === 'object' && typeof o1[key].getMonth !== 'function') {
          if (o1[key]?.Id === o2[key]?.Id) return diff1;
        }
        if (o1[key] === o2[key]) return diff1;
        return {
          ...diff1,
          [key]: o2[key],
        };
      }, {});

      return diff;
    },
    onChange() {
      this.$emit('onFormChange', this.changesAmount);
    },
    cancel() {
      this.model = { ...this.model, ...this.form };
    },
    save() {
      this.$emit('onFormSave', {
        model: this.model,
        cancel: this.cancel,
        setRestorePoint: this.setRestorePoint,
      });
    },
    setRestorePoint() {
      this.storeLessonDetails(this.model);
    },
    refresh() {
      this.model = { ...this.model, ...this.form };
    },
    ...mapMutations('lecturesFeedbackStatement', [
      'storeLessonDetails',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.checkbox-container {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}
</style>
