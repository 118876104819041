<template>
    <div class="DialogReportPaid__Component">
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">הוספת נושא למסלול</p>
                <button type="button" class="delete" @click="$emit('close')" />
            </header>
            <section class="modal-card-body">
                <div class="DialogReportPaid__Component__Loading">
                    <b-field label="הזן שם לנושא">
                        <b-input v-model="model.name"></b-input>
                    </b-field>
                    <b-field label="סטטוס">
                        <b-select v-model="model.status">
                            <option :value="1">1</option>
                            <option :value="0">0</option>
                        </b-select>
                    </b-field>
                </div>
            </section>
            <footer class="modal-card-foot">
                <b-button :loading="isLoading" @click="save" label="שמור" type="is-primary" />
                <b-button :disabled="isLoading" label="סגור" @click="$emit('close')" />
            </footer>
        </div>
    </div>
</template>

<script>
import { ToastProgrammatic as Toast } from "buefy";
import CoursesService from "../services/CoursesService";

export default {
    name: "DialogReportDriverAssign",
    props: ["store", "lectureTrackId"],
    components: {
    },
    computed: {
        courseId() {
            return this.store.state.auth.course?.id;
        },
    },
    data() {
        return {
            submitted: false,
            isLoading: false,
            model: {
                name: null,
                status: 1
            }
        };
    },
    methods: {
        save() {
            if (this.model.name) {
                this.isLoading = true;
                CoursesService.createLectureTrackSubject(this.courseId, +this.lectureTrackId, {
                    ...this.model
                })
                    .then(() => {
                        Toast.open({
                            type: "is-success",
                            message: "הפעולה בוצעה!",
                            duration: 4000,
                        });
                        this.$emit("close");
                        window.location.reload();
                    }).catch(() => {
                        Toast.open({
                            type: "is-danger",
                            message: "לא היה ניתן לבצע את הפעולה, יש לפנות לתמיכה הטכנית.",
                            duration: 8000,
                        });
                    }).finally(() => {
                        this.isLoading = false;
                    });
            }
        },
        onIsCreateTask() {
            if (!this.isCreateTask) {
                this.isModalOpen = true;
            }
        },
        onTaskModelClose() {
            this.isModalOpen = false;
            this.isCreateTask = false;
        },
    },
};
</script>

<style lang="scss"></style>
