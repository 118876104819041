var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "LessonEntityDetails__Component" },
    [
      _c("FieldInlineText", {
        attrs: { label: "שם התרגול" },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.name,
          callback: function($$v) {
            _vm.$set(_vm.model, "name", $$v)
          },
          expression: "model.name"
        }
      }),
      _c("FieldInlineText", {
        attrs: { type: "number", label: "סדר" },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.sortOrder,
          callback: function($$v) {
            _vm.$set(_vm.model, "sortOrder", $$v)
          },
          expression: "model.sortOrder"
        }
      }),
      _c("FieldInlineSelect", {
        attrs: {
          label: "מקבץ",
          filterable: false,
          clearable: true,
          optionsAsync: _vm.getLawCollections
        },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.lawCollectionId,
          callback: function($$v) {
            _vm.$set(_vm.model, "lawCollectionId", $$v)
          },
          expression: "model.lawCollectionId"
        }
      }),
      _c("FieldInlineSelect", {
        attrs: {
          label: "קטגוריות",
          filterable: true,
          clearable: true,
          optionsAsync: _vm.getCategories
        },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.exerciseCategoryId,
          callback: function($$v) {
            _vm.$set(_vm.model, "exerciseCategoryId", $$v)
          },
          expression: "model.exerciseCategoryId"
        }
      }),
      _c(
        "div",
        { staticClass: "checkbox-container" },
        [
          _c(
            "b-switch",
            {
              staticClass: "ltr",
              on: { input: _vm.onChange },
              model: {
                value: _vm.model.status,
                callback: function($$v) {
                  _vm.$set(_vm.model, "status", $$v)
                },
                expression: "model.status"
              }
            },
            [_vm._v(_vm._s(_vm.model.status ? "פעיל" : "לא פעיל"))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }