<template>
  <div class="LessonEntityDetails__Component">
    <div class="Accident__Entity__Details__BaseDetails__Stage">
      <span class="ltr">טווח ציונים תקין:</span>
      <span class="
              Accident__Entity__Details__BaseDetails__Stage__Value
              value
              has-text-weight-bold
            ">{{ model.madadTakinEditor }}</span>
    </div>
    <div class="Accident__Entity__Details__BaseDetails__Stage">
      <span class="ltr">ציון:</span>
      <span class="
              Accident__Entity__Details__BaseDetails__Stage__Value
              value
              has-text-weight-bold
            ">{{ model.grade }}</span>
    </div>
  </div>
</template>

<script>
import FieldInlineText from '@/components/Fields/FieldInlineText.vue';
// import FieldInlineTextArea from '@/components/Fields/FieldInlineTextArea.vue';
// import FieldInlineDate from '@/components/Fields/FieldInlineDate.vue';
// import FieldInlineDateTime from '@/components/Fields/FieldInlineDateTime.vue';
// import FieldInlineSelect from '@/components/Fields/FieldInlineSelect.vue';
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'GroupEntityDetails',
  components: {
    FieldInlineText,
    // FieldInlineDate,
    // FieldInlineSelect,
    // FieldInlineTextArea,
  },
  props: ['readOnly'],
  data() {
    return {
      model: {
        ahuzon: null,
        exerciseId: null,
        exerciseName: null,
        grayGrade: null,
        grayPercent: null,
        madadTakinEditor: null,
        reportUrl: null,
        starGrade: null,
        starPercent: null,
        startDateTime: null,
        status: null,
        userId: null,
        userName: null,
        grade: null
      },
    };
  },
  computed: {
    ...mapGetters('exerciseAnswer', [
      'form',
    ]),
    changesAmount() {
      return Object.keys(this.diff(this.model, this.form)).length;
    },
  },
  created() {
    this.model = { ...this.model, ...this.form };
    if (!this.model.lessonType) this.model.lessonType = '0';
  },
  methods: {
    diff(o1, o2) {
      const diff = Object.keys(o2).reduce((diff1, key) => {
        if (o1[key] && typeof o1[key].getMonth === 'function') {
          if (new Date(o1[key]).toString() === new Date(o2[key]).toString()) return diff1;
        }
        if (typeof o1[key] === 'number') {
          if (parseInt(o1[key], 10) === parseInt(o2[key], 10)) return diff1;
        }
        if (o1[key] && typeof o1[key] === 'object' && typeof o1[key].getMonth !== 'function') {
          if (o1[key]?.Id === o2[key]?.Id) return diff1;
        }
        if (o1[key] === o2[key]) return diff1;
        return {
          ...diff1,
          [key]: o2[key],
        };
      }, {});

      return diff;
    },
    onChange() {
      this.$emit('onFormChange', this.changesAmount);
    },
    cancel() {
      this.model = { ...this.model, ...this.form };
    },
    save() {
      this.$emit('onFormSave', {
        model: this.model,
        cancel: this.cancel,
        setRestorePoint: this.setRestorePoint,
      });
    },
    setRestorePoint() {
      this.storeLessonDetails(this.model);
    },
    refresh() {
      this.model = { ...this.model, ...this.form };
    },
    ...mapMutations('exerciseAnswer', [
      'storeReportDetails',
    ]),
  },
};
</script>

<style></style>
