<template>
    <div class="DialogReportPaid__Component">
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">משובים לוידאו: {{ name }}</p>
                <button type="button" class="delete" @click="$emit('close')" />
            </header>
            <section class="modal-card-body">
                <table v-if="feedbacks" id="customers" class="">
                    <tr>
                        <th>משתמש</th>
                        <th>תאריך</th>
                        <th>דירוג</th>
                        <th>תוכן</th>
                    </tr>
                    <tr class="row" v-for="feedback in feedbacks.feedbacks" :key="feedback.id">
                        <td class="column-item">{{ feedback.userName }}</td>
                        <td class="column-item">{{ parseDate(feedback.dateCreated) }}</td>
                        <td class="column-item">{{ feedback.ratingNumber }}</td>
                        <td class="column-item">{{ feedback.ratingContent }}</td>
                    </tr>
                </table>
                <div v-if="feedbacks">דירוג ממוצע: {{ Math.round((feedbacks.average + Number.EPSILON) * 100) / 100 }}</div>
            </section>
            <footer class="modal-card-foot">
                <b-button :disabled="isLoading" label="סגור" @click="$emit('close')" />
            </footer>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import LessonService from "../services/LessonService";

export default {
    name: "DialogReportDriverAssign",
    props: ["videoId", "name"],
    components: {
    },
    computed: {
        courseId() {
            return this.store.state.auth.course?.id;
        },
    },
    created() {
        console.log(this.videoId);
        LessonService.getVideoFeedback(this.videoId)
            .then((r) => {
                this.feedbacks = r.data;
            });
    },
    data() {
        return {
            submitted: false,
            isLoading: false,
            feedbacks: null,
            model: {
                name: null,
                status: 1
            }
        };
    },
    methods: {
        parseDate(date) {
            return moment(date).format("DD/MM/YYYY HH:mm");
        },
    },
};
</script>

<style scoped lang="scss">
.modal-card {
    height: 600px;
}

.feedbacks__container {
    max-height: 450px;

    .feedback {
        display: flex;
        gap: 10px;
    }
}

#customers tr:nth-child(even){background-color: #f2f2f2;}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: #1E9EB8;
  color: white;
}

#customers {
  max-height: 350px;
  overflow: auto;
  margin: 15px 0;
  border-collapse: collapse;
  width: 100%;
}

#customers td, #customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

table {
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 400px;
}

thead {
    padding-right: 13px;
    flex: 0 0 auto;
}

tbody {
    flex: 1 1 auto;
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
}

tr {
    width: 100%;
    display: table;
    table-layout: fixed;
}
</style>
