var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "LessonEntityDetails__Component" },
    [
      _c("FieldInlineText", {
        attrs: { label: "שם" },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.name,
          callback: function($$v) {
            _vm.$set(_vm.model, "name", $$v)
          },
          expression: "model.name"
        }
      }),
      _c("FieldInlineText", {
        attrs: { label: "הערה פנימית" },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.editorNote,
          callback: function($$v) {
            _vm.$set(_vm.model, "editorNote", $$v)
          },
          expression: "model.editorNote"
        }
      }),
      _c("FieldInlineText", {
        attrs: { label: "הערה לתלמיד" },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.userNote,
          callback: function($$v) {
            _vm.$set(_vm.model, "userNote", $$v)
          },
          expression: "model.userNote"
        }
      }),
      _c("FieldInlineDateTime", {
        attrs: { label: "זמין מ", position: "is-bottom-left" },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.availableFrom,
          callback: function($$v) {
            _vm.$set(_vm.model, "availableFrom", $$v)
          },
          expression: "model.availableFrom"
        }
      }),
      _c("FieldInlineDateTime", {
        attrs: { label: "זמין עד", position: "is-bottom-left" },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.availableUntil,
          callback: function($$v) {
            _vm.$set(_vm.model, "availableUntil", $$v)
          },
          expression: "model.availableUntil"
        }
      }),
      _c("FieldInlineText", {
        attrs: { type: "number", label: "סדר" },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.sortOrder,
          callback: function($$v) {
            _vm.$set(_vm.model, "sortOrder", $$v)
          },
          expression: "model.sortOrder"
        }
      }),
      _c(
        "div",
        { staticClass: "checkbox-container" },
        [
          _c(
            "b-switch",
            {
              staticClass: "ltr",
              on: { input: _vm.onChange },
              model: {
                value: _vm.model.status,
                callback: function($$v) {
                  _vm.$set(_vm.model, "status", $$v)
                },
                expression: "model.status"
              }
            },
            [_vm._v(_vm._s(_vm.model.status ? "פעיל" : "לא פעיל"))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }