var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "LessonEntityDetails__Component" },
    [
      _c("FieldInlineText", {
        attrs: { label: "שם" },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.name,
          callback: function($$v) {
            _vm.$set(_vm.model, "name", $$v)
          },
          expression: "model.name"
        }
      }),
      _c("FieldInlineText", {
        attrs: { label: "תיאור" },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.description,
          callback: function($$v) {
            _vm.$set(_vm.model, "description", $$v)
          },
          expression: "model.description"
        }
      }),
      _c("FieldInlineSelect", {
        attrs: {
          label: "סוג",
          filterable: false,
          clearable: false,
          options: [
            { Value: 1, Text: "בחירה מסולם" },
            { Value: 2, Text: "טקסט חופשי" }
          ]
        },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.type,
          callback: function($$v) {
            _vm.$set(_vm.model, "type", $$v)
          },
          expression: "model.type"
        }
      }),
      _c("FieldInlineText", {
        attrs: { type: "number", label: "סדר" },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.sortOrder,
          callback: function($$v) {
            _vm.$set(_vm.model, "sortOrder", $$v)
          },
          expression: "model.sortOrder"
        }
      }),
      _c(
        "div",
        { staticClass: "checkbox-container" },
        [
          _c(
            "b-switch",
            {
              staticClass: "ltr",
              on: { input: _vm.onChange },
              model: {
                value: _vm.model.status,
                callback: function($$v) {
                  _vm.$set(_vm.model, "status", $$v)
                },
                expression: "model.status"
              }
            },
            [_vm._v(_vm._s(_vm.model.status ? "פעיל" : "לא פעיל"))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }