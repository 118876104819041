var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "LessonEntityDetails__Component" },
    [
      _c("FieldInlineText", {
        attrs: { label: "שם הקבוצה" },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.name,
          callback: function($$v) {
            _vm.$set(_vm.model, "name", $$v)
          },
          expression: "model.name"
        }
      }),
      _c("FieldInlineText", {
        attrs: { label: "תיאור הקבוצה" },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.description,
          callback: function($$v) {
            _vm.$set(_vm.model, "description", $$v)
          },
          expression: "model.description"
        }
      }),
      _c(
        "b-switch",
        {
          staticClass: "ltr",
          on: { input: _vm.onChange },
          model: {
            value: _vm.model.status,
            callback: function($$v) {
              _vm.$set(_vm.model, "status", $$v)
            },
            expression: "model.status"
          }
        },
        [_vm._v(_vm._s(_vm.model.status ? "פעיל" : "לא פעיל"))]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }