<template>
  <div v-if="isReady" class="Reports__View">
    <EntityListLayout v-if="tabs.length" :tabs="tabs" ref="entity" @onNewEntitySave="onNewEntitySave">
      <template #title>שאלות חשיפה לפרק {{ expoChapter.displayName }}</template>
      <template #button>
        <div style="display: flex; align-items: center; gap: 10px;">
          <a class="button-close" @click="createExerciseFromExpo">צור כתרגול רב ברירה</a>
          <a @click="addExpoChapterQuestion" class="link-button">
            הוספת שאלות חשיפה לפרק
          </a>
        </div>
      </template>
      <template v-slot:counter="slotProps">
        סה"כ <b>{{ getTotalRecords(slotProps) }} </b>
      </template>
    </EntityListLayout>
  </div>
</template>
<script>
import CoursesService from "@/services/CoursesService";
import EntityListLayout from "@/components/EntityListLayout.vue";
import ExpoChapterQuestionEntity from "@/views/ExpoChapterQuestionEntity.vue";
import DialogCreateExerciseFromExpo from '@/components/DialogCreateExerciseFromExpo.vue';
import DialogAddExpoChapterQuestion from '@/components/DialogAddExpoChapterQuestion.vue';
import { mapActions } from "vuex";
import {
  ModalProgrammatic as Modal,
} from 'buefy';
import VehicleContractEntity from "@/views/VehicleContractEntity.vue";
// import TableActions from "@/services/TableActionsService";
import FieldService from "@/services/FieldService";
// import FilterService from "@/services/FilterService";
import NewReportEntity from "@/components/Entities/NewReportEntity.vue";
import { saveAs } from "file-saver";

export default {
  name: "Reports",
  data() {
    return {
      tabs: [],
      isStatistics: false
    };
  },
  components: {
    EntityListLayout,
  },
  computed: {
    isUserProfile() {
      return this.$store.state.auth.profileId;
    },
    isAdmin() {
      return this.$store.state.auth?.user?.claims?.isAdmin === "True";
    },
    tabName() {
      return this.selectedTab && this.selectedTab.title;
    },
    courseId() {
      return this.$store.state.auth.course.id;
    },
    expoChapter() {
      return this.$store.state.expoChapter;
    },
    excelMimeType() {
      return "application/vnd.ms-excel";
    },
    isLeasingCompany() {
      return this.$store.state.profile?.IsLeasingCompany;
    },
    isEndUser() {
      return !this.isLeasingCompany;
    },
    isReady() {
      return this.isLeasingCompany !== null;
    },
  },
  created() {
    if (this.isReady) {
      this.init();
      this.create();
    }
  },
  methods: {
    ...mapActions({
      load: "expoChapter/loadExpoChapter",
    }),
    async init(callback) {
      try {
        await this.load({
          id: this.$route.params.id,
        });
        if (callback) {
          callback();
        }
      } catch (err) {
        console.log(err);
      }
    },
    create() {
      const lessonsFields = FieldService.list("expoChapterQuestions");
      // const reportsfilters = FilterService.list();

      const sideViewEntity = {
        component: ExpoChapterQuestionEntity,
        preview: true,
        readOnly: !this.isAdmin,
        title: "פרטי שאלת חשיפה",
        recordUrl: "videos",
        recordTitle: "הצג סרטון",
        hideRecord: true
      };

      const excelExport = [
        {
          title: "יצוא טבלה",
          action: this.export,
        },
        {
          title: "סדר תווית שאלות פעילות מחדש",
          action: this.calculateUnitDisplay,
        },
        {
          title: "מחיקת כל שאלות הפרק",
          action: this.deleteQuestions,
        },
        {
          title: "הצג/הסתר סטטיסטיקה",
          action: this.showStatistics,
        },
      ];
      const fields = [
        { ...lessonsFields.Id, checked: true },
        { ...lessonsFields.UnitDisplayName, checked: true },
        { ...lessonsFields.Question, checked: true },
        { ...lessonsFields.Answer, checked: true },
        { ...lessonsFields.DetailedAnswer, checked: true },
        { ...lessonsFields.Status, checked: true },
        { ...lessonsFields.Count, checked: true },
        { ...lessonsFields.CorrectPercent, checked: true },
      ];

      this.tabs = [
        {
          id: "AllExpoChapterQuestions",
          isPrimary: true,
          title: "כל שאלות החשיפה לפרק",
          newEntityComponent: NewReportEntity,
          getDataAsync: this.getAllExpoChapterQuestions,
          perPage: 20,
          fields,
          // filters: [
          //   { ...reportsfilters.Lessons, selected: this.$route.params.LessonIds },
          // ],
          filters: null,
          sortField: "Id",
          sortOrder: "desc",
          checkableRows: false,
          excelExport,
          fastSearch: true,
          fastSearchOptions: {
            propName: "Terms",
            label: "חיפוש לפי מזהה יחידה",
          },
          sideViewEntity,
          // tableActions: (() => {
          //   let actions = [TableActions.markAsPaid, TableActions.driverAssign, TableActions.addTask];
          //   if (this.isAdmin) {
          //     actions = actions.concat([
          //       {
          //         ...TableActions.changeTransferStatusCompany,
          //         Label: "שינוי סטטוס הסבת חברה",
          //       },
          //       {
          //         ...TableActions.changeTransferStatusDriver,
          //         Label: "שינוי סטטוס הסבת נהג",
          //       },
          //       TableActions.changeReportStatus,
          //     ]);
          //     if (this.isUserProfile) {
          //       actions = actions.concat([TableActions.removeFromProfile]);
          //     }
          //   }
          //   return actions;
          // })(),
        },
      ];
    },
    addExpoChapterQuestion() {
      Modal.open({
        component: DialogAddExpoChapterQuestion,
        props: {
          store: this.$store
        },
        canCancel: false,
        hasModalCard: true,
        trapFocus: true,
      });
    },
    createExerciseFromExpo() {
      Modal.open({
        component: DialogCreateExerciseFromExpo,
        props: {
          store: this.$store,
          router: this.$router
        },
        canCancel: false,
        hasModalCard: true,
        trapFocus: true,
      });
    },
    async onPlateNumberClick(context) {
      if (context.item.IsVehicleMine) {
        context.default();
      } else {
        const contract = await CoursesService.getReportContract(context.item.Id);
        this.$refs.entity
          .setExtraSideMenu({
            component: VehicleContractEntity,
            title: "חוזה לרכב",
            recordUrl: "contracts",
            preview: true,
            readOnly: true,
            Id: contract.data.VehicleContractId,
          })
          .show();
      }
    },
    showNewEntity() {
      this.$refs.entity.newEntityMenu();
    },
    onNewEntitySave() {
      this.$refs.entity.onNewEntityCancel();
    },
    export({ loading, query }) {
      loading(true);
      CoursesService.exportExpoChapterQuestions(this.$route.params.id, query)
        .then((r) => {
          this.saveFile(r.data, `ייצוא ${this.expoChapter.displayName}`);
        })
        .finally(() => {
          loading(false);
        });
    },
    calculateUnitDisplay({ loading, query }) {
      loading(true);
      CoursesService.resetQuestionsLabel(this.$route.params.id)
        .then(() => {
          // this.getAllExpoChapterQuestions(query);
          window.location.reload();
          loading(false);
        })
    },
    deleteQuestions({ loading, query }) {
      loading(true);
      CoursesService.deleteExpoChapterQuestions(this.$route.params.id)
        .then(() => {
          // this.tabs[0].getDataAsync();
          window.location.reload();
          loading(false);
        });
    },
    showStatistics({ loading, query, getData, setFields}){
      this.isStatistics = !this.isStatistics;
      if (this.isStatistics) {
        setFields(['id', 'unitDisplayName', 'question', 'answer', 'detailedAnswer', 'status', 'count', 'percent']);
      };
      getData(this.tabs[0]);
    },
    getAllExpoChapterQuestions({ ...rest }) {
      return CoursesService.getExpoChapterQuestion(this.$route.params.id, rest, this.isStatistics).then((r) => ({
        data: {
          TotalResults: r.data.totalResults,
          Items: r.data.items,
        },
      }));
    },
    getPayments({ cancelToken }) {
      return CoursesService.getPayments(cancelToken.token).then((r) => ({
        data: {
          TotalResults: r.data.length,
          Items: r.data,
        },
      }));
    },
    getTotalRecords(slotProps) {
      return slotProps.tab && slotProps.tab.total && slotProps.tab.total;
    },
    saveFile(data, filename) {
      const blob = new Blob([data], { type: this.excelMimeType });
      saveAs(blob, `${filename}.xlsx`);
    },
    getFromStatusApproved(query) {
      // eslint-disable-next-line eqeqeq
      if (typeof query.StatusApproved == "boolean") {
        if (query.StatusApproved) {
          if (query.Status) {
            if (!query.Status.includes("ApproveForPayment")) {
              query.Status.push("ApproveForPayment");
            }
          } else query.Status = ["ApproveForPayment"];
        } else {
          if (query.Status) {
            query.Status = query.Status.filter(
              (item) => item !== "ApproveForPayment"
            );
          }
          query.NotStatus = ["ApproveForPayment"];
        }
        delete query.StatusApproved;
      }
      if (query.StatusApproved === null) {
        delete query.NotStatus;
        if (!query.Status) delete query.Status;
        else {
          query.Status = query.Status.filter(
            (item) => item !== "ApproveForPayment"
          );
        }
        delete query.StatusApproved;
      }
      return query;
    },
  },
  watch: {
    isReady(value) {
      if (value) {
        this.create();
      }
    },
  },
};
</script>
<style scoped lang="scss">
.link-button {
  background: #ff7a59;
  border: none;
  border-radius: 3px;
  padding: 14px 25px;
  color: white;
  cursor: pointer;
}

.button-close {
  background: white;
  color: #ff7a59;
  border: none;
  border: 1px solid #ff7a59;
  padding: 13px 30px;
  border-radius: 3px;
  outline: none;
  cursor: pointer;
}
</style>
