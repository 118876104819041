var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Reports__View" },
    [
      _c("EntityListLayout", {
        ref: "entity",
        attrs: { tabs: _vm.tabs },
        on: { onNewEntitySave: _vm.onNewEntitySave },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [_vm._v("ספרים")]
            },
            proxy: true
          },
          {
            key: "button",
            fn: function() {
              return [
                _c(
                  "a",
                  {
                    staticClass: "link-button",
                    on: { click: _vm.addLessonBook }
                  },
                  [_vm._v(" שיוך ספר לקורס ")]
                )
              ]
            },
            proxy: true
          },
          {
            key: "counter",
            fn: function(slotProps) {
              return [
                _vm._v(' סה"כ '),
                _c("b", [_vm._v(_vm._s(_vm.getTotalRecords(slotProps)) + " ")])
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }