import { render, staticRenderFns } from "./LecturesTeachers.vue?vue&type=template&id=7c978040&scoped=true&"
import script from "./LecturesTeachers.vue?vue&type=script&lang=js&"
export * from "./LecturesTeachers.vue?vue&type=script&lang=js&"
import style0 from "./LecturesTeachers.vue?vue&type=style&index=0&id=7c978040&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c978040",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Mobaxe\\Nevo.Simulator\\Nevo.Simulator.Admin.Web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7c978040')) {
      api.createRecord('7c978040', component.options)
    } else {
      api.reload('7c978040', component.options)
    }
    module.hot.accept("./LecturesTeachers.vue?vue&type=template&id=7c978040&scoped=true&", function () {
      api.rerender('7c978040', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/LecturesTeachers.vue"
export default component.exports